import React, { useState } from "react";
import "./CarouselsBlock.scss";
import { getCookie } from "../utils";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import ScreenShot1 from "./../pictures/pictures/ScreenShot1.jpg";
import ScreenShot2 from "./../pictures/pictures/ScreenShot2.jpg";
import ScreenShot3 from "./../pictures/pictures/ScreenShot3.jpg";
import ScreenShot4 from "./../pictures/pictures/ScreenShot4.jpg";
import ScreenShot5 from "./../pictures/pictures/ScreenShot5.jpg";
import ScreenShot6 from "./../pictures/pictures/ScreenShot6.jpg";
import BT_Arrow from "./../pictures/icons/BT_Arrow.png";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

export const Carousels = () => {
  const [open, setOpen] = useState(false);
  const [openDelay, setOpenDelay] = useState(false);
  const [pictureIndex, setPictureIndex] = useState(0);

  const { t, i18n } = useTranslation();

  const urls = [
    ScreenShot1,
    ScreenShot2,
    ScreenShot3,
    ScreenShot4,
    ScreenShot5,
    ScreenShot6
  ];

  const handleGoTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const openLightbox = (index) => {
    setOpen((prevOpen) => {
      if (!prevOpen) {
        setPictureIndex(index);
        handleDelay(true);
      }
      return !prevOpen;
    });
  };
  
  const handleCloseLightbox = () => {
    setOpen(false);
    handleDelay(false);
  };

  const handleDelay = (open) => {
    if (open) {
      setTimeout(() => {
        setOpenDelay(open);
      }, 200);
    } else {
      setOpenDelay(open)
    }
  }
  

  const handlePrevPicture = () => {
    setPictureIndex((prevIndex) => (prevIndex + urls.length - 1) % urls.length);
  };

  const handleNextPicture = () => {
    setPictureIndex((prevIndex) => (prevIndex + 1) % urls.length);
  };

  return (
    <section id="media">
      <div className="wrapper">
        <div className="title_wrapper">
          <h1>{t("description.part3")}</h1>
        </div>
        <ul className="gallery">
          {urls.map((url, index) => (
            <li className="screenshot" key={index} id={`id_${index}`} onClick={() => openLightbox(index)}>
              <img src={url} loading="eager" alt={`Screenshot ${index + 0}`} />
            </li>
          ))}
        </ul>

        {open && (
          <Lightbox
            mainSrc={urls[pictureIndex]}
            nextSrc={urls[(pictureIndex + 0) % urls.length]}
            prevSrc={urls[(pictureIndex + urls.length - 0) % urls.length]}
            onCloseRequest={handleCloseLightbox} 
            onMovePrevRequest={handlePrevPicture}
            onMoveNextRequest={handleNextPicture}
          />
        )}

        <a className="goTop"  onClick={handleGoTop}>
          <img src={BT_Arrow} alt="Go Top" />
          <p>{t("description.goTop")}</p>
        </a>
      </div>
    </section>
  );
};

