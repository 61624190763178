import { Routes, Route } from "react-router-dom";
import React, { useState,useEffect } from "react";
import { Home } from "./pages/Home";
import "./App.css";
import { NavbarContainer } from "./components/Navbar";
import { Footer } from "./components/Footer";
import "./i18n";
import {
  handleChangeLanguage,
  getCookie
} from "./utils";
function App() {
  const Steam_url = "https://store.steampowered.com/app/1277230";
  const [currentlanguage, setCurrentLanguage] = useState("");
  let language = navigator.language;
  useEffect(() => {
    handleLanguage();
  }, [currentlanguage]);
  const handleLanguage = () => {
    if (getCookie("i18next")) {
      let lng = getCookie("i18next");
      setCurrentLanguage(lng);
      return;
    }
    if (language === "zh-TW" || language === "zh" || language === "zh-CN") {
      setCurrentLanguage("zh");
      document.cookie = "i18next=zh";
    } else {
      setCurrentLanguage("en");
      document.cookie = "i18next=en";
    }
  };
  return (
    <div className="App">
      <NavbarContainer Steam_url={Steam_url} handleChangeLanguage={handleChangeLanguage} handleLanguage={handleLanguage} currentlanguage={currentlanguage} setCurrentLanguage={setCurrentLanguage}/>
      <main>
        <Routes>
          <Route path="/" element={<Home Steam_url={Steam_url} handleChangeLanguage={handleChangeLanguage} handleLanguage={handleLanguage}  currentlanguage={currentlanguage} setCurrentLanguage={setCurrentLanguage} />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}

export default App;


