import React, { useEffect, useState } from "react";
import "./Navbar.scss";
import logo from "./../pictures/pictures/BT_Home.png";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  handleScoll,
  // handleChangeLanguage,
  // getCookie,
  handlePostion,
} from "./../utils";
import { useTranslation } from "react-i18next";

export const NavbarContainer = ({ Steam_url,handleChangeLanguage,handleLanguage,currentlanguage,setCurrentLanguage }) => {
  // let language = navigator.language;
  // const [currentlanguage, setCurrentLanguage] = useState("");
  const [open, setOpen] = useState(false);
  // dective language;
  const { t, i18n } = useTranslation();
  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };
  handleScoll();
  // useEffect(() => {
  //   handleLanguage();
  // }, [currentlanguage]);
  // 預設
  // const handleLanguage = () => {
  //   if (getCookie("i18next")) {
  //     let lng = getCookie("i18next");
  //     setCurrentLanguage(lng);
  //     return;
  //   }
  //   if (language === "zh-TW" || language === "zh" || language === "zh-CN") {
  //     setCurrentLanguage("zh");
  //     document.cookie = "i18next=zh";
  //   } else {
  //     setCurrentLanguage("en");
  //     document.cookie = "i18next=en";
  //   }
  // };
  
  return (
    <>
      <nav className="navbarWrapper">
        <div className="Container-left-Wrapper">
          <div
            className="img-container"
            onClick={handleScrollToTop}
          >
            <img src={logo} />
          </div>
          <div className="left-list">
            <ul>
              <li
                onClick={() => {
                  handlePostion("pic-1");
                }}
              >
                {t("description.part1")}
              </li>

              <li
                onClick={() => {
                  handlePostion("pic-2");
                }}
              >
                {t("description.part2")}
              </li>
              <li
                onClick={() => {
                  handlePostion("media");
                }}
              >
                {t("description.part3")}
              </li>
            </ul>
          </div>
        </div>
        <div className="Container-right-Wrapper">
          <div className="lang-website">
            <ul>
              <li
                className={
                  currentlanguage === "en" ? "lang-block active" : "lang-block"
                }
                onClick={() => {
                  document.cookie = "i18next=en";
                  setCurrentLanguage("en");
                  handleChangeLanguage("en");
                }}
              >
                EN
              </li>
              <li
                className={
                  currentlanguage === "jp" ? "lang-block active" : "lang-block"
                }
                onClick={() => {
                  document.cookie = "i18next=jp";
                  setCurrentLanguage("jp");
                  handleChangeLanguage("jp");
                }}
              >
                JP
              </li>
              <li
                className={
                  currentlanguage === "zh" ? "lang-block active" : "lang-block"
                }
                onClick={() => {
                  document.cookie = "i18next=zh";
                  setCurrentLanguage("zh");
                  handleChangeLanguage("zh");
                }}
              >
                CHT
              </li>
              {/* <li
                className={
                  currentlanguage === "ch" ? "lang-block active" : "lang-block"
                }
                onClick={() => {
                  document.cookie = "i18next=ch";
                  setCurrentLanguage("ch");
                  handleChangeLanguage("ch");
                }}
              >
                CH
              </li> */}
            </ul>
          </div>
          <a
            className="nav_buy_button"
            href={Steam_url}
            target={"_blank"}
            rel="noreferrer"
          >
            <b>{t("description.part4")}</b>
          </a>
          <div
            className="lang-mobile"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <AiOutlineClose /> : <AiOutlineMenu />}
          </div>
          {/* mobile-menu */}
          <div className="nav-menu">
            <ul className={!open ? "nav-menu-items" : "nav-menu-items show"}>
              <ol>
                <ul>
                  <li
                    onClick={() => {
                      handlePostion("pic-1");
                      setOpen(!open);
                    }}
                  >
                    {t("description.part1")}
                  </li>

                  <li
                    onClick={() => {
                      handlePostion("pic-2");
                      setOpen(!open);
                    }}
                  >
                    {t("description.part2")}
                  </li>
                  <li
                    onClick={() => {
                      handlePostion("media");
                      setOpen(!open);
                    }}
                  >
                    {t("description.part3")}
                  </li>
                </ul>
              </ol>
              <ol>
                <span className="lang">
                  LANGUAGE
                  <hr />
                </span>

                <li
                  className={
                    currentlanguage === "zh"
                      ? "lang-block active"
                      : "lang-block"
                  }
                  onClick={() => {
                    document.cookie = "i18next=zh";
                    setCurrentLanguage("zh");
                    handleChangeLanguage("zh");
                    setOpen(!open);
                  }}
                >
                  繁體中文
                </li>
                {/* <li
                  className={
                    currentlanguage === "ch"
                      ? " lang-block active"
                      : "lang-block"
                  }
                  onClick={() => {
                    document.cookie = "i18next=ch";
                    setCurrentLanguage("ch");
                    handleChangeLanguage("ch");
                  }}
                >
                  简体中文
                </li> */}
                <li
                  className={
                    currentlanguage === "en"
                      ? "lang-block active"
                      : "lang-block"
                  }
                  onClick={() => {
                    document.cookie = "i18next=en";
                    setCurrentLanguage("en");
                    handleChangeLanguage("en");
                    setOpen(!open);
                  }}
                >
                  English
                </li>
                <li
                  className={
                    currentlanguage === "jp"
                      ? "lang-block active"
                      : "lang-block"
                  }
                  onClick={() => {
                    document.cookie = "i18next=jp";
                    setCurrentLanguage("jp");
                    handleChangeLanguage("jp");
                    setOpen(!open);
                  }}
                >
                  日本語
                </li>
              </ol>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
