import i18n from "i18next";
export function handleScoll() {
  window.onscroll = function () {
    let elementLogo = document
      .querySelector(".logo_warpper")
      .getBoundingClientRect().top;
    let elementTopOne = document
      .querySelector(".one")
      .getBoundingClientRect().top;
    let elementTopTwo = document
      .querySelector(".two")
      .getBoundingClientRect().top;
    let elementTopThree = document
      .querySelector(".three")
      .getBoundingClientRect().top;
    let elementTopFour = document
      .querySelector(".four")
      .getBoundingClientRect().top;
    if (elementLogo > 0) {
      document.querySelector(".button_area").classList.add("move");
      document.querySelector(".logo").classList.add("move");
      document.querySelector(".logo_mobile_wapper").classList.add("move");
      document.querySelector(".media_wrapper_mobile").classList.add("move");
      document.querySelector(".subtitle_app").classList.add("move");
    } else {
      document.querySelector(".button_area").classList.remove("move");
      document.querySelector(".logo").classList.remove("move");
      document.querySelector(".logo_mobile_wapper").classList.remove("move");
      document.querySelector(".media_wrapper_mobile").classList.remove("move");
      document.querySelector(".subtitle_app").classList.remove("move");
    }
    if (elementTopOne < window.innerHeight - 200) {
      document.querySelector(".one").classList.add("animels");
      document.querySelectorAll(".word_area")[0].classList.add("move");
    } else {
      document.querySelector(".one").classList.remove("animels");
      document.querySelectorAll(".word_area")[0].classList.remove("move");
    }
    if (elementTopTwo < window.innerHeight - 300) {
      document.querySelector(".two").classList.add("animels_left");
      document.querySelectorAll(".word_area")[1].classList.add("move");
    } else {
      document.querySelector(".two").classList.remove("animels_left");
      document.querySelectorAll(".word_area")[1].classList.remove("move");
    }
    if (elementTopThree < window.innerHeight - 300) {
      document.querySelector(".three").classList.add("animels");
      document.querySelectorAll(".word_area")[2].classList.add("move");
    } else {
      document.querySelector(".three").classList.remove("animels");
      document.querySelectorAll(".word_area")[2].classList.remove("move");
    }
    if (elementTopFour < window.innerHeight - 300) {
      document.querySelector(".four").classList.add("animels_left");
      document.querySelectorAll(".word_area")[3].classList.add("move");
    } else {
      document.querySelector(".four").classList.remove("animels_left");
      document.querySelectorAll(".word_area")[3].classList.remove("move");
    }
  };
}

export function handlePostion(props) {
  let id = document.getElementById(props);
  id.scrollIntoView();
}

export function handleChangeLanguage(lang) {
  i18n.changeLanguage(lang);
}

export function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}
