import React from "react";
import "./PictureBlock.scss";
import { useTranslation } from "react-i18next";
import { handleScoll } from "./../utils";
import bg1 from "../pictures/background/bg1.png";
import bg2 from "../pictures/background/bg2.png";
import bg3 from "../pictures/background/bg3.png";
import bg4 from "../pictures/background/bg4.png";
import bg1_900 from "../pictures/background/bg1_900.png";
import bg2_900 from "../pictures/background/bg2_900.png";
import bg1_app from "../pictures/background/bg1_app.png";
import bg2_app from "../pictures/background/bg2_app.png";
import bg3_app from "../pictures/background/bg3_app.png";
import bg4_app from "../pictures/background/bg4_app.png";

export const PictureBlock = ({ Steam_url }) => {
  const { t, i18n } = useTranslation();
  handleScoll();
  return (
    <>
      <section id="pic-1" className="picture-wrapper">
        <div className="pic-container">
          <div className="bgimg">
            <picture>
              <source media="(max-width:600px)" srcSet={bg1_app} />
              <source media="(max-width:900px)" srcSet={bg1_900} />
              <img className="one" src={bg1} loading="lazy" />
            </picture>
          </div>
          <div className="wrapper">
            <div className="word_area">
              <h1>
                <span className="into_title"> {t("into.01")}</span>
                <span className="into">{t("into.01_2")}</span>
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section id="pic-2" className="picture-wrapper">
        <div className="pic-container">
          <div className="bgimg left">
            <picture>
              <source media="(max-width:600px)" srcSet={bg2_app} />
              <source media="(max-width:900px)" srcSet={bg2_900} />
              <img className="two" src={bg2} loading="lazy" />
            </picture>
          </div>
          <div className="wrapper left">
            <div className="word_area">
              <h1 className="left_word">
                <span>{t("into.02")}</span>
                <span>{t("into.02_2")}</span>
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section id="pic-3" className="picture-wrapper">
        <div className="pic-container">
          <div className="bgimg">
            <picture>
              <source media="(min-width:900px)" srcSet={bg3} />
              <img className="three" src={bg3_app} loading="lazy" />
            </picture>
          </div>
          <div className="wrapper">
            <div className="word_area">
              <h1>
                <span>
                  {t("into.03")}
                  <strong>{t("into.03_2")}</strong>
                </span>
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section id="pic-4" className="picture-wrapper">
        <div className="pic-container">
          <div className="bgimg left">
            <picture>
              <source media="(min-width:900px)" srcSet={bg4} />
              <img className="four" src={bg4_app} loading="lazy" />
            </picture>
          </div>
          <div className="wrapper left last">
            <div className="word_area">
              <h1 className="left_word">
                <span>{t("into.04")}</span>
                <span>{t("into.04_2")}</span>
                <span>
                  <a target="_blank" href={Steam_url} rel="noreferrer">
                    <div className="buy_button">{t("description.part5")}</div>
                  </a>
                </span>
              </h1>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
