import React, { useState,useEffect } from 'react';
import "./FullscreenWarning.scss";
import { useTranslation } from "react-i18next";
import { Button } from 'react-bootstrap';
import {
  // handleChangeLanguage,
  // getCookie
} from "./../utils";
export const FullscreenWarning = ({handleChangeLanguage,handleLanguage,currentlanguage,setCurrentLanguage}) => {
  // const [currentlanguage, setCurrentLanguage] = useState("");
  const [isVisible, setIsVisible] = useState(true);
  const { t, i18n } = useTranslation();

  const handleEnter = () => {
    setIsVisible(false);
    document.querySelector("#CryPony").play();
  };

  const handleExit = () => {
    window.location.href = 'https://www.crespirit.com/';
  };
  // let language = navigator.language;
  useEffect(() => {
    // handleLanguage();
    // 當 isVisible 為 true 時禁用滾動
    if (isVisible) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [isVisible]);
  // const handleLanguage = () => {
  //   if (getCookie("i18next")) {
  //     let lng = getCookie("i18next");
  //     setCurrentLanguage(lng);
  //     return;
  //   }
  //   if (language === "zh-TW" || language === "zh" || language === "zh-CN") {
  //     setCurrentLanguage("zh");
  //     document.cookie = "i18next=zh";
  //   } else {
  //     setCurrentLanguage("en");
  //     document.cookie = "i18next=en";
  //   }
  // };
  return (
    <>
      {isVisible && (
        <div className="fullscreen-warning">
          <div className="warning-wrapper">
            <h1>---- {t("warning.title")}  ----</h1>
            <p>{t("warning.content1")}
              <br></br>{t("warning.content2")}
              <br></br><br></br>{t("warning.content3")}
              <br></br>{t("warning.content4")}</p>
              <div className="warningBtn">
              <Button onClick={handleEnter} className="intoWeb">{t("warning.into")} </Button>
              <Button onClick={handleExit}  className="outWed">{t("warning.out")} </Button>
              </div>
              <div className="lang-website">
                <ul>
                  <li
                    className={
                      currentlanguage === "en" ? "lang-block active" : "lang-block"
                    }
                    onClick={() => {
                      document.cookie = "i18next=en";
                      setCurrentLanguage("en");
                      handleChangeLanguage("en");
                    }}
                  >
                    EN
                  </li>
                  <li
                    className={
                      currentlanguage === "jp" ? "lang-block active" : "lang-block"
                    }
                    onClick={() => {
                      document.cookie = "i18next=jp";
                      setCurrentLanguage("jp");
                      handleChangeLanguage("jp");
                    }}
                  >
                    JP
                  </li>
                  <li
                    className={
                      currentlanguage === "zh" ? "lang-block active" : "lang-block"
                    }
                    onClick={() => {
                      document.cookie = "i18next=zh";
                      setCurrentLanguage("zh");
                      handleChangeLanguage("zh");
                    }}
                  >
                    CHT
                  </li>
                </ul>
              </div>

          </div>
        </div>
      )}
    </>
  );
}
