import React from "react";
import { FullscreenWarning }from './FullscreenWarning';
import { TitleBlock } from "./TitleBlock";
import { PictureBlock } from "./PictureBlock";
import { Carousels } from "./CarouselsBlock";
export const Home = ({ Steam_url,handleChangeLanguage,handleLanguage,currentlanguage,setCurrentLanguage }) => {
  return (
    <>
      <FullscreenWarning handleChangeLanguage={handleChangeLanguage} handleLanguage={handleLanguage} currentlanguage={currentlanguage} setCurrentLanguage={setCurrentLanguage}/>
      <TitleBlock Steam_url={Steam_url} />
      <PictureBlock Steam_url={Steam_url} />
      <Carousels />
    </>
  );
};
