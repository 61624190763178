import React from "react";
import "./Footer.scss";
import crespirit from "./../pictures/icons/Crespirit_logo.png";
import { FaSteam } from "react-icons/fa";
import { IoLogoFacebook, IoLogoTwitter } from "react-icons/io";
import { handlePostion } from "./../utils";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t, i18n } = useTranslation();
  
  return (
    <footer>
      <div className="footerWrapper">
        <div className="left_side">
          <a
            href="https://www.crespirit.com/?"
            target="_blank"
            rel="noreferrer"
          >
            <div className="crespirit_icons">
              <img src={crespirit} />
            </div>
          </a>
        </div>
        <div className="right_side">
          <div className="left_area">
            <div className="top_area">
              <div className="contactus_lineArea"></div>
            </div>
            <div className="bot_area">
              <div className="copyright">
                © 2015 - 2023 Crespirit co., Ltd./ All rights reserved.
              </div>
            </div>
          </div>
          <div className="right_area">
            <div className={`grading ${t("description.18add")}`}></div>
          </div>
        </div>
      </div>
    </footer>
  );
};
