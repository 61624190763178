import React, { useState, useEffect } from "react";
import "./TitleBlock.scss";
import Logo from "./../pictures/background/main_logo.png";
import LogoApp from "./../pictures/background/Logo_CryingPony_App.png";
import { useTranslation } from "react-i18next";
import { getCookie } from "./../utils";
import FacebookLogo from "./../pictures/icons/BT_Facebook.svg";
import TwitterLogo from "./../pictures/icons/BT_Twitter.svg";
import PlurkLogo from "./../pictures/icons/BT_Plurk.svg";
import YoutubeLogo from "./../pictures/icons/BT_Youtube.svg";
import Subtitle from "./../pictures/object/Subtitle.png";
import MainArt_BG_mp4 from "./../pictures/MainArt_BG.mp4";
import MainArt_BG_ogg from "./../pictures/MainArt_BG.ogv";
import MainArt_BG_webm from "./../pictures/MainArt_BG.webm";
import MainArt_BG_app_mp4 from "./../pictures/MainArt_BG_App.mp4";
import SketchEdge from "./../pictures/object/SketchEdge.png";

import SteamLogo from "./../pictures/icons/Icon_Steam.png";
import SwitechLogo from "./../pictures/icons/Icon_Switch.png";
import PlaystationLogo from "./../pictures/icons/Icon_Playstation.png";

export const TitleBlock = ({ Steam_url }) => {
  const [open, Setopen] = useState(false);
  const [play, Setplay] = useState(false);
  const { t, i18n } = useTranslation();
  let language = getCookie("i18next");

  // 各媒體連結
  const Facebook_url = "https://www.facebook.com/CreSpirit";
  const Twitter_url = "https://twitter.com/crespirit";
  const Plurk_url = "https://www.plurk.com/crespirit";
  // 預告片連結
  const Youtube_url =
    "https://www.youtube.com/channel/UCPAbKW5Lh3j2KBpwkENH3Fg";
  const Youtube_url2 = 
    "https://youtu.be/EsFAciSMLRo"
  useEffect(() => {
    document.querySelector(".button_area").classList.add("move");
    document.querySelector(".logo").classList.add("move");
    document.querySelector(".logo_mobile_wapper").classList.add("move");
    document.querySelector(".media_wrapper_mobile").classList.add("move");
    document.querySelector(".subtitle_app").classList.add("move");
    // document.querySelector("#CryPony").play();
    setTimeout(() => {
      document.querySelector(".secret").click();
    }, [100]);
  }, []);
  return (
    <div id="first" className="first_block">
      <div className="top_area">
        <div className="moive_container">
          <img className="SketchEdge" src={SketchEdge}/>
          <div className={play ? "video_wrapper show" : "video_wrapper"}>
            <video muted loop playsInline id="CryPony">
              <source src={MainArt_BG_webm} type="video/webm" />
              <source src={MainArt_BG_mp4} type="video/mp4" />
              <source src={MainArt_BG_ogg} type="video/ogg" />
              Your browser does not support HTML5 video.
            </video>
            <video muted loop playsInline id="CryPony_app">
              <source src={MainArt_BG_app_mp4} type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          </div>
          {open ? (
            <>
              {/* <div className="video_area">
                <div
                  className="cancel"
                  onClick={() => {
                    Setopen(!open);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="45"
                    height="45"
                    fill="currentColor"
                    className="bi bi-x-lg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                  </svg>
                </div>
                <div className="video-wrapper">
                  <div className="video-container">
                    <iframe
                      src="https://www.youtube.com/embed/EsFAciSMLRo"
                      title="YouTube video player"
                      allow="accelerometer;clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    />
                  </div>
                </div>
              </div> */}
            </>
          ) : (
            <div></div>
          )}
          <section className="media_wrapper">
            <ul>
              <li>
                <a href={Facebook_url} target="_blank" rel="noreferrer">
                  <img src={FacebookLogo} />
                </a>
              </li>
              <li>
                <a href={Twitter_url} target="_blank" rel="noreferrer">
                  <img src={TwitterLogo} />
                </a>
              </li>
               <li>
                <a href={Plurk_url} target="_blank" rel="noreferrer">
                  <img src={PlurkLogo}/>
                </a>
              </li> 
              <li>
                <a href={Youtube_url} target="_blank" rel="noreferrer">
                  <img src={YoutubeLogo} />
                </a>
              </li>
            </ul>
          </section>
          <div className="logo_warpper">
            <div className="logo">
              <img src={Logo} loading="lazy" alt="Prepare for a Pony" />
              <img src={Subtitle} loading="lazy" />
            </div>
            <div className="logo_mobile_wapper">
              <img src={LogoApp} loading="lazy" alt="Prepare for a Pony" />
            </div>
            <div className="subtitle_app">
              <img src={Subtitle} loading="lazy" />
            </div>
            <div className="button_area">
              <a target="_blank" href={Steam_url} rel="noreferrer">
                <div className="buy_button">{t("description.part5")}</div>
              </a>
              <a href={Youtube_url2} target="_blank" rel="noreferrer">
                {/* <div
                  className="secret"
                  onClick={() => {
                    document
                      .querySelector(".video_wrapper")
                      .classList.add("show");
                    document.querySelector("#CryPony_app").play();
                  }}
                ></div> */}
                <div
                  // onClick={() => {
                  //   Setopen(!open);
                  // }}
                  className="trailer_button"
                >
                  {t("description.part6")}
                </div>
              </a>
            </div>
            <div className="media_wrapper_mobile">
              <ul>
                <li>
                  <a href={Facebook_url} target="_blank" rel="noreferrer">
                    <img src={FacebookLogo} />
                  </a>
                </li>
                <li>
                  <a href={Twitter_url} target="_blank" rel="noreferrer">
                    <img src={TwitterLogo} />
                  </a>
                </li>
                <li>
                  <a href={Plurk_url} target="_blank" rel="noreferrer">
                    <img src={PlurkLogo}/>
                  </a>
                </li>
                <li>
                  <a href={Youtube_url} target="_blank" rel="noreferrer">
                    <img src={YoutubeLogo} />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="buying_pop_up_wrapper"></div>
        </div>
      </div>
    </div>
  );
};
